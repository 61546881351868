import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { ReactComponent as Telegramm } from '../../assets/img/icons/messengers/telegramm.svg'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { ReactComponent as Whatsapp } from '../../assets/img/icons/messengers/whatsapp.svg'
import { messengersUrl } from '../../../static/db.json'

const Description = styled(Text.Medium)`
  white-space: pre-line;
`

const Messengers = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  width: fit-content;
`

export const contactList = [
  {
    title: () => (
      <Title.H4>
        Общие вопросы и служба
        <br /> заботы о клиентах
      </Title.H4>
    ),
    description: () => (
      <>
        <a href="mailto:care@youtalk.ru" rel="noreferrer" target="_blank">
          care@youtalk.ru
        </a>
        <a href="tel: +7(495)165-87-80">+7(495)165-87-80</a>
        <Messengers>
          <a href={messengersUrl.whatsapp} rel="noreferrer" target="_blank">
            <Whatsapp />
          </a>
          <a
            href="https://t.me/YouTalk_clients_bot"
            rel="noreferrer"
            target="_blank"
          >
            <Telegramm />
          </a>
        </Messengers>
      </>
    )
  },
  {
    title: () => (
      <Title.H4>
        Корпоративные
        <br /> услуги
      </Title.H4>
    ),
    description: () => (
      <>
        <a href="mailto:business@youtalk.ru" rel="noreferrer" target="_blank">
          business@youtalk.ru
        </a>
        <Link to="/b2b/">youtalk.ru/b2b</Link>
      </>
    )
  },
  {
    title: () => <Title.H4>Маркетинг, партнёрства и PR</Title.H4>,
    description: () => (
      <a href="mailto:marketing@youtalk.ru" rel="noreferrer" target="_blank">
        marketing@youtalk.ru
      </a>
    )
  },
  {
    title: () => <Title.H4>Реквизиты</Title.H4>,
    description: () => (
      <Description
        dangerouslySetInnerHTML={{
          __html: `ООО «ЮТОК»\n ОГРН 1207700002223\n ИНН 7751175007\n\n Юридический адрес: 105005,\nМосква, Вн.тер.г.муниципальный округ\n Басманный, ул 2-я Бауманская\nд. 9/23, стр. 3, ком. 19\n Расчетный счет: 40702810310000614234\n Банк: АО «ТИНЬКОФФ БАНК»\n БИК: 044525974\n Корр. счет: 30101810145250000974`
        }}
      ></Description>
    )
  },
  {
    title: () => <Title.H4>Сотрудничество с психологами</Title.H4>,
    description: () => (
      <>
        <a href="mailto:psyteam@youtalk.ru" rel="noreferrer" target="_blank">
          psyteam@youtalk.ru
        </a>
        <Link to="/be-in-team/">youtalk.ru/be-in-team</Link>
      </>
    )
  },
  {
    title: () => <Title.H4>Работа в YouTalk</Title.H4>,
    description: () => (
      <a href="mailto:jobs@youtalk.ru" rel="noreferrer" target="_blank">
        jobs@youtalk.ru
      </a>
    )
  },
  {
    title: () => <Title.H4>Бухгалтерия</Title.H4>,
    description: () => (
      <>
        <a href="mailto:office@youtalk.ru" rel="noreferrer" target="_blank">
          office@youtalk.ru
        </a>
      </>
    )
  }
]
