import React from 'react'
import styled from 'styled-components'
import { ContactsCard } from '../../molecules/ContactsCard'
import { ReactComponent as SkolkovoLogo } from './img/skolkovo-logo.svg'
import { Text } from '../../atoms/Text'
import { contactList } from '../../molecules/ContactsCard/contactList'
import { size } from '../../constants'

const Skolkovo = styled(({ className }) => (
  <div className={className}>
    <Text.Average>
      ООО «ЮТОК» — организация, осуществляющая деятельность в области
      информационных технологий, являющаяся правообладателем программы для ЭВМ
      «Платформа YouTalk v.1.1.», зарегистрированной в Роспатенте (номер
      свидетельства о гос.регистрации программы для ЭВМ №2022681155 от
      10.11.2022 г.). ООО «ЮТОК» является резидентом Сколково с 2022 г.
    </Text.Average>
    <a
      href="https://navigator.sk.ru/orn/1124440"
      rel="noreferrer"
      target="_blank"
    >
      <SkolkovoLogo />
    </a>
  </div>
))`
  width: 718px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${size.lg}) {
    width: 624px;
  }

  @media (max-width: ${size.md}) {
    width: 354px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 180px 154px auto;
  grid-gap: 24px;

  & div:nth-child(4) {
    grid-column: 1;
    grid-row: 2 / span 2;
  }

  @media (max-width: ${size.lg}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto) 106px repeat(3, auto);
    max-width: calc(83% + 24px);

    & div:nth-child(3) {
      grid-column: 1;
      grid-row: 6;
    }

    & div:nth-child(4) {
      grid-column: 1;
      grid-row: 2 / span 4;
    }

    & div:nth-child(6) {
      grid-column: 2;
      grid-row: 4 / span 3;
    }
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }

  @media (max-width: calc(${size.sm})) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(7, auto);
    grid-gap: 16px;
    max-width: unset;

    ${ContactsCard} {
      max-width: 100%;
    }

    & div:nth-child(2) {
      grid-column: 1;
      grid-row: 3;
    }

    & div:nth-child(3) {
      grid-column: 1;
      grid-row: 6;
    }

    & div:nth-child(4) {
      grid-column: 1;
      grid-row: 2;
    }

    & div:nth-child(5) {
      grid-column: 1;
      grid-row: 4;
    }

    & div:nth-child(6) {
      grid-column: 1;
      grid-row: 7;
    }

    & div:nth-child(7) {
      grid-column: 1;
      grid-row: 5;
    }
  }

  ${ContactsCard}:nth-child(7) {
    height: 106px;
  }

  @media (max-width: ${size.lg}) {
    ${ContactsCard}:nth-child(6) {
      height: 106px;
    }
  }
`

export const ContactsList = styled(({ className }) => (
  <div className={className}>
    <Grid>
      {contactList.map(({ title, description }, index) => (
        <ContactsCard key={index}>
          {title()}
          {description()}
        </ContactsCard>
      ))}
    </Grid>
    <Skolkovo />
  </div>
))`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${size.sm}) {
    gap: 16px;
  }
`
