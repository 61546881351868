import React from 'react'
import styled from 'styled-components'

export const ContactsCard = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 24px;
  box-sizing: border-box;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 30px 50px #eff5fb;
    border-radius: 24px;
    z-index: -1;
  }

  & h4 {
    margin-bottom: 8px;
  }

  & div:last-child {
    margin-top: 8px;
  }

  & a:nth-child(3) {
    margin-top: 8px;
  }

  a {
    text-decoration: none;
    color: #2864a2;
    width: fit-content;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    &:visited,
    &:hover,
    &:active {
      color: #2864a2;
      text-decoration: none;
    }
  }
`
