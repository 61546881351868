import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import SEO, { getSeoMedia } from '../atoms/SEO'
import styled from 'styled-components'
import { BreadCrumbs } from '../molecules/BreadCrumbs'
import { ContactsList } from '../organisms/ContactList'
import { Grid } from '../atoms/Grid'
import { JsonLd } from 'gatsby-plugin-next-seo'
import { Title } from '../atoms/Title'
import { size } from '../constants'

const DecorationImg = styled.img.attrs(() => ({
  src: '../img/contacts/decoration.svg',
  alt: 'decoration'
}))``

const ContactsJSONLd = () => (
  <JsonLd
    json={{
      '@context': 'http://www.schema.org',
      '@type': 'Organization',
      url: String(process.env.GATSBY_SITEURL),
      name: 'ООО «ЮТОК»',
      address: {
        '@type': 'PostalAddress',
        streetAddress:
          'Юридический адрес: 105005, Москва, Вн.тер.г.муниципальный округ Басманный, ул 2-я Бауманская д. 9/23, стр. 3, ком. 19',
        addressLocality: 'Москва',
        postalCode: '105005',
        addressCountry: 'Россия',
        email: 'care@youtalk.ru'
      }
    }}
  />
)

const Contacts = styled(({ className }) => (
  <App>
    <SEO
      children={<ContactsJSONLd />}
      description="Контактные данные для связи | онлайн-сервис психологической помощи YouTalk"
      images={getSeoMedia([
        {
          pathname: '/img/og/contacts.png',
          alt: 'contacts'
        }
      ])}
      title="Контакты"
    />
    <Header />
    <BreadCrumbs />
    <div className={className}>
      <Grid>
        <Title.H1>Контакты</Title.H1>
        <ContactsList />
        <DecorationImg />
      </Grid>
    </div>
    <Footer />
  </App>
))`
  position: relative;
  padding-bottom: 51px;

  @media (max-width: ${size.lg}) {
    padding-bottom: 32px;
  }

  @media (max-width: ${size.md}) {
    padding-bottom: 24px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 32px;
  }

  ${Grid} {
    position: relative;

    @media (max-width: ${size.sm}) and (min-width: ${size.xxs}) {
      width: 100%;
      float: left;
    }
  }

  ${Title.H1} {
    margin: 64px 0 48px 0;

    @media (max-width: calc(${size.md} + 1px)) {
      margin: 72px 0 40px 0;
    }

    @media (max-width: ${size.sm}) {
      margin: 40px 0;
    }

    @media (max-width: ${size.xs}) {
      margin: 32px 0;
    }

    @media (max-width: ${size.xxs}) {
      margin: 16px 0 24px 0;
    }
  }

  & ${DecorationImg} {
    pointer-events: none;
    z-index: -1;
    width: 391px;
    height: 287px;
    position: absolute;
    bottom: -42px;
    right: 0;

    @media (max-width: ${size.lg}) {
      width: 378px;
      height: 277px;
      right: -54px;
      bottom: -13px;
    }

    @media (max-width: ${size.md}) {
      right: 0;
      bottom: 0;
    }

    @media (max-width: ${size.sm}) {
      display: none;
    }
  }
`

export default Contacts
